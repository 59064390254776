<template>
  <v-card
    class="mx-3 mb-5"
    max-width="300"
    dark
  >
    <v-img
        class="white--text align-end"
        height="200px"
        src="https://media.giphy.com/media/kQ3FSVoJrkYWk/giphy.gif" 
      >
        <v-card-title text="purple lighten-3">AWS IoT Assignement</v-card-title>
    </v-img>

    <v-card-text>
        <div>BUILT WITH.</div>
        <div div>AWS, RaspberryPi and Python</div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="purple lighten-3"
        text
        href="https://github.com/rogieBeer/Pub_IoTAssi"
      >
        CODE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "ProjectCard7"

}
</script>

<style scoped>

</style>