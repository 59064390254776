<template>
    <v-card class="centre-me"
    tile
    dark
    id="about"
    >
      <!-- Mobile -->
      <div v-if="$vuetify.breakpoint.mdAndDown">
        <v-container>
          <v-row class="centre-me">
                <AboutText/>
          </v-row>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-img
              class="align-self-stretch shakeme"
              max-width="200"           
              src="@/assets/Head.png"
              align="center"
              justify="center"
              >
              </v-img>
          </v-row>
          <v-container>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
              <v-container>
                <v-text class="text-center">
                  <p class="text-center">I started studying and working in the audio sector, transferring to retail and distributions with 10 years experience to boot. Working in these sectors allowed me to hone a range of transferable skills that revolved around people and management. I have just completed a Bachelors in Information Sciences at 34, I selected papers that revolved around web technologies and completed the degree with a B+ average and 90% in my Capstone project. My studies have helped me grow a great foundation around web technologies, agile principles, IS and IT. Both studies and work have proven my ability to meet deadlines, achieve project objectives, solve problems and prioritise critical tasks, while maintaining the high standards expected of my role and studies. What I will bring is a willingness to learn and continue to learn by myself, a mature attitude and an understanding of how important a client is to any project. I am currently looking for a grad position where I can take my foundation and grow.</p>
                </v-text>
              </v-container>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col align="center">
                <v-list-item-title class="font-weight-bold">THE TECHNICAL STUFF</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Python</v-list-item-title>
                    <v-list-item-subtitle>2 years university experience</v-list-item-subtitle>
                    <v-list-item-subtitle>My comfortable language</v-list-item-subtitle>
                    <v-list-item-title>Java</v-list-item-title>
                    <v-list-item-subtitle>1 year university experience</v-list-item-subtitle>
                    <v-list-item-title>MEVN stack</v-list-item-title>
                    <v-list-item-subtitle>Used in a couple of Uni Projects</v-list-item-subtitle>
                    <v-list-item-title>Firebase and Heroku</v-list-item-title>
                    <v-list-item-subtitle>Experience hosting university projects</v-list-item-subtitle>
                    <v-list-item-title>MongoDB and SQL</v-list-item-title>
                    <v-list-item-subtitle>Prefer NoSQL, but will SQL :)</v-list-item-subtitle>
                    <v-list-item-title>AWS</v-list-item-title>
                    <v-list-item-subtitle>Have used with IoT</v-list-item-subtitle>
                    <v-list-item-title>Github</v-list-item-title>
                    <v-list-item-subtitle>Used for version control</v-list-item-subtitle>
                    <v-list-item-title>C# and ASP.Net</v-list-item-title>
                    <v-list-item-subtitle>Limited Knowledge but used in 1 Project</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col align="center">
                <v-list-item-title class="font-weight-bold">THE PERSONAL STUFF</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>People Management</v-list-item-title>
                    <v-list-item-subtitle>5+ years in a leadership role, prefer servant leadership.</v-list-item-subtitle>
                    <v-list-item-title>Critical Thinking</v-list-item-title>
                    <v-list-item-subtitle>University helped cement this thought process</v-list-item-subtitle>
                    <v-list-item-title>Creativity</v-list-item-title>
                    <v-list-item-subtitle>I have a creative soul since 1987</v-list-item-subtitle>
                    <v-list-item-title>Decision Making</v-list-item-title>
                    <v-list-item-subtitle>I don't think I would be here without this ability.</v-list-item-subtitle>
                    <v-list-item-title>Persistence</v-list-item-title>
                    <v-list-item-subtitle>University really cemeted my belief that I am persistant</v-list-item-subtitle>
                    <v-list-item-title>Problem Solving</v-list-item-title>
                    <v-list-item-subtitle>I might not be the fastest but I think I have proven this in results</v-list-item-subtitle>
                    <v-list-item-title>Trust</v-list-item-title>
                    <v-list-item-subtitle>I believe a Team is nothing without this.</v-list-item-subtitle>
                    <v-list-item-title>Agile</v-list-item-title>
                    <v-list-item-subtitle>Multiple papers on Agile Principles</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  color="purple lighten-3"
                  text
                  href="resume.pdf"
                >
                  CHECK OUT MY RESUME!
                </v-btn>
                <v-btn
                  color="purple lighten-3"
                  text
                  @click="$vuetify.goTo('#project')"
                >
                  BACK TO PROJECTS
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </div>
      <!-- LG Desktop -->
      <div v-else-if="$vuetify.breakpoint.lgOnly" class="container">
        <v-container>
          <v-row class="centre-me">
                <AboutText/>
          </v-row>
          <v-row>
            <v-col
              class="fill-height ma-0"
              align="left"
              justify="center"
            >
              <v-container>
                <v-text >
                  I started studying and working in the audio sector, transferring to retail and distributions with 10 years experience to boot. Working in these sectors allowed me to hone a range of transferable skills that revolved around people and management. I have just completed a Bachelors in Information Sciences at 34, I selected papers that revolved around web technologies and completed the degree with a B+ average and 90% in my Capstone project. My studies have helped me grow a great foundation around web technologies, agile principles, IS and IT. Both studies and work have proven my ability to meet deadlines, achieve project objectives, solve problems and prioritise critical tasks, while maintaining the high standards expected of my role and studies. What I will bring is a willingness to learn and continue to learn by myself, a mature attitude and an understanding of how important a client is to any project. I am currently looking for a grad position where I can take my foundation and grow.
                </v-text>
              </v-container>
            </v-col>
            <v-col
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-img
                class="align-self-stretch shakeme"
                max-width="250"           
                src="@/assets/Head.png"
                align="center"
                justify="center"
                >
                </v-img>
              </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col>
                <v-list-item-title class="font-weight-bold">THE CODE</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Python</v-list-item-title>
                    <v-list-item-subtitle>2 years university experience</v-list-item-subtitle>
                    <v-list-item-subtitle>My comfortable language</v-list-item-subtitle>
                    <v-list-item-title>Java</v-list-item-title>
                    <v-list-item-subtitle>1 year university experience</v-list-item-subtitle>
                    <v-list-item-title>MEVN stack</v-list-item-title>
                    <v-list-item-subtitle>Used in a couple of Uni Projects</v-list-item-subtitle>
                    <v-list-item-title>MongoDB and SQL</v-list-item-title>
                    <v-list-item-subtitle>Prefer NoSQL, but will SQL :)</v-list-item-subtitle>
                    <v-list-item-title>C# and ASP.Net</v-list-item-title>
                    <v-list-item-subtitle>Limited Knowledge but used in 1 Project</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item-title class="font-weight-bold">THE OTHER STUFF</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Firebase and Heroku</v-list-item-title>
                    <v-list-item-subtitle>Experience hosting university projects</v-list-item-subtitle>
                    <v-list-item-title>AWS</v-list-item-title>
                    <v-list-item-subtitle>Have used with IoT</v-list-item-subtitle>
                    <v-list-item-title>Github</v-list-item-title>
                    <v-list-item-subtitle>Used for version control</v-list-item-subtitle>
                    <v-list-item-title>Agile</v-list-item-title>
                    <v-list-item-subtitle>Multiple papers on Agile Principles</v-list-item-subtitle>
                    <v-list-item-title>Adobe Creative Suite</v-list-item-title>
                    <v-list-item-subtitle>Have used for years on personal projects</v-list-item-subtitle>
                    <v-list-item-title>Cubase & Fruitloops</v-list-item-title>
                    <v-list-item-subtitle>Can manipulate digital audio proficiently</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item-title class="font-weight-bold">THE SKILLS</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>People Management</v-list-item-title>
                    <v-list-item-subtitle>5+ years in a leadership role, prefer servant leadership.</v-list-item-subtitle>
                    <v-list-item-title>Critical Thinking</v-list-item-title>
                    <v-list-item-subtitle>University helped cement this thought process</v-list-item-subtitle>
                    <v-list-item-title>Creativity</v-list-item-title>
                    <v-list-item-subtitle>I have a creative soul since 1987</v-list-item-subtitle>
                    <v-list-item-title>Decision Making</v-list-item-title>
                    <v-list-item-subtitle>I don't think I would be here without this ability.</v-list-item-subtitle>
                    <v-list-item-title>Persistence</v-list-item-title>
                    <v-list-item-subtitle>University really cemeted my belief that I am persistant</v-list-item-subtitle>
                    <v-list-item-title>Problem Solving</v-list-item-title>
                    <v-list-item-subtitle>I might not be the fastest but I think I have proven this in results</v-list-item-subtitle>
                    <v-list-item-title>Trust</v-list-item-title>
                    <v-list-item-subtitle>I believe a Team is nothing without this.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  color="purple lighten-3"
                  text
                  href="resume.pdf"
                >
                  CHECK OUT MY RESUME!
                </v-btn>
                <v-btn
                  color="purple lighten-3"
                  text
                  @click="$vuetify.goTo('#project')"
                >
                  BACK TO PROJECTS
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </div>
      <!-- XL Desktop -->
      <div v-else-if="$vuetify.breakpoint.xlOnly" class="container">
        <v-container>
          <v-row class="centre-me">
                <AboutText/>
          </v-row>
          <v-container>
            <v-row
            no-gutters
            >
              <v-col
                class="fill-height ma-0"
                align="left"
                justify="center"
                md="4"
              >
              <v-container>
                <v-text >
                  I started studying and working in the audio sector, transferring to retail and distributions with 10 years experience to boot. Working in these sectors allowed me to hone a range of transferable skills that revolved around people and management. I have just completed a Bachelors in Information Sciences at 34, I selected papers that revolved around web technologies and completed the degree with a B+ average and 90% in my Capstone project. My studies have helped me grow a great foundation around web technologies, agile principles, IS and IT. Both studies and work have proven my ability to meet deadlines, achieve project objectives, solve problems and prioritise critical tasks, while maintaining the high standards expected of my role and studies. What I will bring is a willingness to learn and continue to learn by myself, a mature attitude and an understanding of how important a client is to any project. I am currently looking for a grad position where I can take my foundation and grow.
                </v-text>
              </v-container>
              </v-col>
              <v-col
                class="fill-height ma-0"
                align="center"
                justify="center"
                md="3"
              >
                <v-img
                class="align-self-stretch shakeme"
                max-width="250"           
                src="@/assets/Head.png"
                align="center"
                justify="center"
                >
                </v-img>
              </v-col>
              <v-col
              md="2"
              >
                <v-list-item-title class="font-weight-bold">THE TECHNICAL STUFF</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Python</v-list-item-title>
                    <v-list-item-subtitle>2 years university experience</v-list-item-subtitle>
                    <v-list-item-subtitle>My comfortable language</v-list-item-subtitle>
                    <v-list-item-title>Java</v-list-item-title>
                    <v-list-item-subtitle>1 year university experience</v-list-item-subtitle>
                    <v-list-item-title>MEVN stack</v-list-item-title>
                    <v-list-item-subtitle>Used in a couple of Uni Projects</v-list-item-subtitle>
                    <v-list-item-title>Firebase and Heroku</v-list-item-title>
                    <v-list-item-subtitle>Experience hosting university projects</v-list-item-subtitle>
                    <v-list-item-title>MongoDB and SQL</v-list-item-title>
                    <v-list-item-subtitle>Prefer NoSQL, but will SQL :)</v-list-item-subtitle>
                    <v-list-item-title>AWS</v-list-item-title>
                    <v-list-item-subtitle>Have used with IoT</v-list-item-subtitle>
                    <v-list-item-title>Github</v-list-item-title>
                    <v-list-item-subtitle>Used for version control</v-list-item-subtitle>
                    <v-list-item-title>C# and ASP.Net</v-list-item-title>
                    <v-list-item-subtitle>Limited Knowledge but used in 1 Project</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
              md="3"
              >
                <v-list-item-title class="font-weight-bold">THE PERSONAL STUFF</v-list-item-title>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>People Management</v-list-item-title>
                    <v-list-item-subtitle>5+ years in a leadership role, prefer servant leadership.</v-list-item-subtitle>
                    <v-list-item-title>Critical Thinking</v-list-item-title>
                    <v-list-item-subtitle>University helped cement this thought process</v-list-item-subtitle>
                    <v-list-item-title>Creativity</v-list-item-title>
                    <v-list-item-subtitle>I have a creative soul since 1987</v-list-item-subtitle>
                    <v-list-item-title>Decision Making</v-list-item-title>
                    <v-list-item-subtitle>I don't think I would be here without this ability.</v-list-item-subtitle>
                    <v-list-item-title>Persistence</v-list-item-title>
                    <v-list-item-subtitle>University really cemeted my belief that I am persistant</v-list-item-subtitle>
                    <v-list-item-title>Problem Solving</v-list-item-title>
                    <v-list-item-subtitle>I might not be the fastest but I think I have proven this in results</v-list-item-subtitle>
                    <v-list-item-title>Trust</v-list-item-title>
                    <v-list-item-subtitle>I believe a Team is nothing without this.</v-list-item-subtitle>
                    <v-list-item-title>Agile</v-list-item-title>
                    <v-list-item-subtitle>Multiple papers on Agile Principles</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  color="purple lighten-3"
                  text
                  href="resume.pdf"
                >
                  CHECK OUT MY RESUME!
                </v-btn>
                <v-btn
                  color="purple lighten-3"
                  text
                  @click="$vuetify.goTo('#project')"
                >
                  BACK TO PROJECTS
                </v-btn>
              </v-col>
            </v-row>
            </v-container>
        </v-container>
      </div>        
    </v-card> 
</template>

<script>
import AboutText from '@/components/primaryContent/about/AboutText.vue'

export default {
    name: 'Projects',
    data: () => ({

    }),
    components: {
      AboutText,  
    }, 
  }
</script>

<style scoped>
.centre-me {
    justify-content: center;
}

.shakeme:hover {
   animation: shakeImg 1s;
   animation-iteration-count: infinite;
}
@keyframes shakeImg {
   0% { transform: translate(1px, 1px) rotate(3deg); }
   20% { transform: translate(-3px, 0px) rotate(5deg); }
   30% { transform: translate(3px, 2px) rotate(-5deg); }
   50% { transform: translate(-1px, 2px) rotate(-7deg); }
   70% { transform: translate(3px, 1px) rotate(-9deg); }
   90% { transform: translate(1px, 2px) rotate(5deg); }
   100% { transform: translate(1px, -2px) rotate(-5deg); }
}
</style>